﻿@use "../basics";

@use "../controls/button";
@use "../controls/form/index";
@use "../controls/password-strength-meter";
@use "../controls/search";
@use "../controls/tab";
@use "../controls/dialog";

@use "../components/header";
@use "../components/footer";
@use "../components/navigation";
@use "../components/message";
@use "../components/article";
@use "../components/hero";
@use "../components/session-timeout";
@use "../components/progress";

@use "../utilities/spacer";
@use "../utilities/visibility";
@use "../utilities/flex";
@use "../utilities/grid";
@use "../utilities/functions" as util;

.module-verification {
	@include basics.typ-body();
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: util.spacer(5) 0;
	flex-wrap: wrap;

	@media (min-width: map-get(basics.$breakpoints, s)) {
		margin-block: util.spacer(10);
    }

	@media (min-width: map-get(basics.$breakpoints, l)) {
		margin-block: util.spacer(8);
		flex-direction: row;
    }

	&--login {
		@media (min-width: map-get(basics.$breakpoints, l)) {
			grid-column: 1 / -1;
			display: grid;
			grid-template-columns: repeat(13, 1fr);
		}
	}

	p {
		margin: 0;
	}

	&__section {
		&--size-s {
			width: 325px;
		}

		&--login {
			grid-column: 4 / 7;
		}

		&--register {
			grid-column: 8 / 11;
		}

		&--spacer {
			margin-block: util.spacer(12);
			border-bottom: var(--bor-001);

			@media (min-width: map-get(basics.$breakpoints, l)) {
				margin-inline: util.spacer(12);
				margin-block: 0;
				border-right: var(--bor-001);
				border-bottom: none;
				width: 1px;
			}
		}
	}

	&__title {
		display: flex;
		flex-direction: column;
		gap: util.spacer(2);
		margin-top: util.spacer(8);
		text-align: center;
	}

	&__content {
		margin-top: util.spacer(8);
	}

	&__terms {
		margin-top: util.spacer(6);

		&.tk-form-checkbox label {
			display: -webkit-box;
		}

		.tk-button {
			display: contents;
		}
	}

	&__actions {
		@media (min-width: map-get(basics.$breakpoints, s)) {
			margin-right: auto;
		}

		&--before {
			display: flex;
			align-items: center;
		}
	}

	&--confirmation {
		height: 100%;
		align-items: center;
		position: relative;

		&__actions {
			width: 100%;
			position: absolute;
			bottom: 0;

			@media (min-width: map-get(basics.$breakpoints, xs)) {
				width: fit-content;
				position: unset;
			}
		}
	}

	&--error {
		height: 100%;
		align-items: center;
		position: relative;
		padding-bottom: util.spacer(16); /* button height + 32px */

		@media (min-width: map-get(basics.$breakpoints, xs)) {
			padding-bottom: unset;
		}

		button {
			width: 100%;
			position: absolute;
			bottom: 0;
			left: 0;

			@media (min-width: map-get(basics.$breakpoints, xs)) {
				width: fit-content;
				position: unset;
			}
		}

		&__content {
			display: flex;
			align-items: center;
			width: 100%;
			max-width: 375px;

			tk-form-validator {
				width: 100%;
			}
		}
	}

	&__form {
		display: flex;
		flex-direction: column;
		align-items: center;
		row-gap: util.spacer(9);

		label {
			text-align: start;
		}
	}
}
